import Badge from 'components/Badges/Badge';
import {IconFA} from 'components/Icons';
import {ImageAvatar} from 'components/Images';
import {
  ACCOUNT_ROLE_OPTIONS,
  ACCOUNT_ROLE_OPTIONS_COLOR,
  ACCOUNT_ROLES,
  COMPANY_STATUSES,
} from 'configs';
import {ENABLE_FREE_TRIAL} from 'configs/enable-features';
import {IAF_DC_URL} from 'configs/Environment';
import {
  SUBSCRIPTION_BILLING_METHOD,
  SUBSCRIPTION_TYPE,
  SUBSCRIPTION_TYPE_COLOR,
  SUBSCRIPTION_TYPE_LABEL,
  SUBSCRIPTION_TYPE_LEVEL,
} from 'configs/membership';
import {SUBSCRIPTION_STATUS_LABEL} from 'configs/subscription';
import {COMPANY_TYPE} from 'configs/types';
import {useAuth} from 'contexts/AuthContext';
import {useLimits} from 'contexts/LimitsContext';
import {useMembership} from 'contexts/MembershipContext';
import {get, isEqual} from 'lodash';
import useManageSubscription from 'modules/membership/useManageSubscription';
import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import {
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Button,
} from 'reactstrap';
import urljoin from 'url-join';
import {getFormattedNumber} from 'utils/numbers';
import {buildUrlParams} from 'utils/string';

function PrivateNavUser() {
  const {pathname} = useLocation();
  const {data, isLoading} = useLimits();
  const {onManage} = useManageSubscription();
  const {account, companyType, isMainAccount, isDefault} = useAuth();
  const {isTrial, subscriptionLevel, isSubscribed, billingMethod, isTrialDone} =
    useMembership();
  const isCompanySuspended =
    get(account, 'company.company_status') === COMPANY_STATUSES.suspended;

  const entityId = get(account, 'entity_id', '');
  const accountRole = get(account, 'account_role', '');
  const isSuperAdmin = accountRole === ACCOUNT_ROLES.super_admin;

  const {
    total_limit,
    cert_limit_reached,
    available_limit_left,
    company_limit_reached,
  } = data;

  const isManualMethod = billingMethod === SUBSCRIPTION_BILLING_METHOD.manual;

  let liveProfile;
  let liveProfileTag;
  let liveProfileProps;

  let userTag;
  let userProps;

  let logoutTag;
  let logoutProps;

  switch (companyType) {
    default:
      liveProfile = null;
      liveProfileTag = null;
      liveProfileProps = {};

      userTag = Link;
      userProps = {
        to: '/settings/user-profile',
      };

      logoutTag = Link;
      logoutProps = {
        to: '/logout',
      };
      break;
    case COMPANY_TYPE.ab:
      liveProfile = urljoin(IAF_DC_URL, `/accreditation-body/${entityId}`);
      liveProfileTag = 'a';
      liveProfileProps = {
        href: liveProfile,
      };

      userTag = 'a';
      userProps = {
        href: urljoin(IAF_DC_URL, `/settings/user-profile`),
      };

      logoutTag = 'a';
      logoutProps = {
        href: urljoin(IAF_DC_URL, `/logout`),
      };
      break;
    case COMPANY_TYPE.cb:
      liveProfile = urljoin(IAF_DC_URL, `/certification-body/${entityId}`);
      liveProfileTag = 'a';
      liveProfileProps = {
        href: liveProfile,
      };

      userTag = 'a';
      userProps = {
        href: urljoin(IAF_DC_URL, `/settings/user-profile`),
      };

      logoutTag = 'a';
      logoutProps = {
        href: urljoin(IAF_DC_URL, `/logout`),
      };
      break;
    case COMPANY_TYPE.ce:
      liveProfile = `/certified-entity/${entityId}`;
      liveProfileTag = Link;
      liveProfileProps = {
        to: liveProfile,
      };

      userTag = 'a';
      userProps = {
        href: '/settings/user-profile',
      };

      logoutTag = 'a';
      logoutProps = {
        href: `/logout`,
      };
      break;
    case COMPANY_TYPE.mncb:
      userTag = 'a';
      userProps = {
        href: urljoin(IAF_DC_URL, `/settings/user-profile`),
      };

      logoutTag = 'a';
      logoutProps = {
        href: urljoin(IAF_DC_URL, `/logout`),
      };
      break;
  }

  const isVuAccount =
    companyType === COMPANY_TYPE.company || companyType === COMPANY_TYPE.ce;
  const isEnterprise = isEqual(
    subscriptionLevel,
    SUBSCRIPTION_TYPE_LEVEL.enterprise
  );
  const isFree = isEqual(subscriptionLevel, SUBSCRIPTION_TYPE_LEVEL.free);
  const isNearLimit = (available_limit_left / total_limit) * 100 <= 30; // show only if 30% left
  const hasFreeTrial = ENABLE_FREE_TRIAL && !isTrialDone && !subscriptionLevel;

  const enableUpgrade =
    (subscriptionLevel === 0 && !isTrial) ||
    isNearLimit ||
    cert_limit_reached ||
    company_limit_reached;

  function generateLink() {
    if (!subscriptionLevel && !isTrialDone && hasFreeTrial) {
      // if free and trial not availed
      return buildUrlParams('/membership/plans/checkout', {
        plan: 'free_trial',
        redirect: pathname,
      });
    }

    if (isTrial) {
      // push upgrade to basic
      return buildUrlParams('/membership/plans/checkout', {
        plan: SUBSCRIPTION_TYPE.basic,
        redirect: pathname,
      });
    }

    // proceed normal upgrade to plans
    return buildUrlParams('/membership/plans', {redirect: pathname});
  }

  return (
    <UncontrolledDropdown nav>
      <DropdownToggle nav className="p-0">
        <ImageAvatar
          image={account.account_image}
          name={account.account_first_name + ' ' + account.account_last_name}
          size="40"
        />
      </DropdownToggle>

      <DropdownMenu right>
        {isVuAccount && (
          <DropdownItem>
            <Badge
              pill
              className="mr-1"
              color={SUBSCRIPTION_TYPE_COLOR[subscriptionLevel || 0]}
            >
              {SUBSCRIPTION_TYPE_LABEL[subscriptionLevel || 0]} Plan{' '}
              {isTrial && `- ${SUBSCRIPTION_STATUS_LABEL['trialing']}`}
            </Badge>
            <Badge color={ACCOUNT_ROLE_OPTIONS_COLOR[accountRole]} pill outline>
              {ACCOUNT_ROLE_OPTIONS[accountRole]}
            </Badge>
          </DropdownItem>
        )}

        {available_limit_left >= 0 && isSubscribed && !isLoading && (
          <DropdownItem
            style={{fontSize: '14px'}}
            className="d-block d-xl-none text-dark font-weight-bold"
          >
            Available Credits: {getFormattedNumber(available_limit_left)}
          </DropdownItem>
        )}

        {enableUpgrade && (
          <DropdownItem
            tag={Link}
            to={generateLink}
            style={{fontSize: '14px'}}
            className="d-block d-xl-none mr-3 rounded-sm text-danger"
          >
            {hasFreeTrial ? 'Try Basic Plan for Free' : 'Upgrade'}
          </DropdownItem>
        )}

        <DropdownItem tag={Link} to="/dashboard">
          <IconFA name="dashboard" className="mr-1" />
          Dashboard
        </DropdownItem>

        <DropdownItem divider />

        {liveProfile && !isCompanySuspended && (
          <React.Fragment>
            <DropdownItem tag={liveProfileTag} {...liveProfileProps}>
              <IconFA name="bolt" className="mr-1" />
              Live Profile
            </DropdownItem>

            <DropdownItem divider />
          </React.Fragment>
        )}

        <DropdownItem header>Settings</DropdownItem>

        <DropdownItem tag={userTag} {...userProps}>
          <IconFA name="cog" className="mr-1" />
          User Settings
        </DropdownItem>

        {isMainAccount && isSuperAdmin && (
          <DropdownItem tag={'a'} href="/membership/overview">
            <IconFA name="dollar" className="mr-1" />
            Membership & billing
          </DropdownItem>
        )}

        {((isVuAccount &&
          isSuperAdmin &&
          !(isManualMethod && isEnterprise) && // hide on manual - enterprise, show on stripe - enterprise
          !isFree) || // hide on free accounts
          isTrial) && ( // allow on free trial accounts
          <DropdownItem tag={Button} onClick={onManage}>
            <IconFA name="credit-card" className="mr-1" />
            Update Payment Details
          </DropdownItem>
        )}

        {(companyType === COMPANY_TYPE.ab ||
          companyType === COMPANY_TYPE.cb) && (
          <DropdownItem
            tag="a"
            href={
              isDefault
                ? urljoin(IAF_DC_URL, '/branches') //hq cb/ab account
                : urljoin(IAF_DC_URL, '/settings/account') //non-hq cb/ab account
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconFA name="building-o" className="mr-1" />
            Account Settings
          </DropdownItem>
        )}

        <DropdownItem divider />

        <DropdownItem tag={logoutTag} {...logoutProps} className="text-danger">
          <IconFA name="sign-out" className="mr-1" />
          Logout
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

export default PrivateNavUser;
