/*
####### subscriptions
subscription_upgrade_confirmation: Subscription Upgrade Confirmation
subscription_success: Successful Subscription
subscription_payment_confirmation_as: Successful Payment Confirmation: Add Seat
subscription_payment_confirmation: Success Payment Confirmation
subscription_payment_failed: Failed Payment
subscription_payment_reminder: Payment method expiry reminder
out_of_credit: Out of credit
near_verification_limit: Nearing Verification Limit

####### data_import
data_import_upload_error: Error Upload
data_import_upload_success: Success Upload
data_import_upload_partial: Partial Upload

####### verification_access

verification_grace_period_expired: _expired: Expired Verification Grace Period
verification_grace_period_nearing_expiration: Grace period nearing expiration

####### watchlist_updates
watchlist_certifications_update: Certifications Update
*/

export const NOTIFICATION_CODE = {
  // Subscriptions
  subscription_upgrade_confirmation: 'subscription_upgrade_confirmation',
  subscription_success: 'subscription_success',
  subscription_payment_confirmation_as: 'subscription_payment_confirmation_as',
  subscription_payment_confirmation: 'subscription_payment_confirmation',
  subscription_payment_failed: 'subscription_payment_failed',
  subscription_payment_reminder: 'subscription_payment_reminder',
  near_verification_limit: 'near_verification_limit',
  out_of_credit: 'out_of_credit',
  subscription_request_approved: 'subscription_request_approved',
  subscription_request_declined: 'subscription_request_declined',
  subscription_request_cancelled: 'subscription_request_cancelled',
  subscription_request_review: 'subscription_request_review',

  customer_request_under_review: 'customer_request_under_review',
  customer_request_declined: 'customer_request_declined',
  customer_request_cancelled: 'customer_request_cancelled',
  customer_request_approved: 'customer_request_approved',

  // Data Import
  data_import_upload_error: 'data_import_upload_error',
  data_import_upload_success: 'data_import_upload_success',
  data_import_upload_partial: 'data_import_upload_partial',

  // Verification Access
  verification_grace_period_expired: 'verification_grace_period_expired',
  verification_grace_period_nearing_expiration:
    'verification_grace_period_nearing_expiration',

  // Watchlist Updates
  watchlist_certifications_update: 'watchlist_certifications_update',
};

export const NOTIFICATION_CATEGORY = {
  watchlist_update: 'Watchlist Update',
  data_import: 'Data Import',
  subscription: 'Subscription',
  verification_access: 'Verification Access',
  enquiry: 'Enquiry',
};

export const NOTIFICATION_CATEGORY_LABEL = {
  [NOTIFICATION_CODE.subscription_upgrade_confirmation]:
    NOTIFICATION_CATEGORY.subscription,
  [NOTIFICATION_CODE.subscription_success]: NOTIFICATION_CATEGORY.subscription,
  [NOTIFICATION_CODE.subscription_payment_confirmation_as]:
    NOTIFICATION_CATEGORY.subscription,
  [NOTIFICATION_CODE.subscription_payment_confirmation]:
    NOTIFICATION_CATEGORY.subscription,
  [NOTIFICATION_CODE.subscription_payment_failed]:
    NOTIFICATION_CATEGORY.subscription,
  [NOTIFICATION_CODE.near_verification_limit]:
    NOTIFICATION_CATEGORY.subscription,
  [NOTIFICATION_CODE.out_of_credit]: NOTIFICATION_CATEGORY.subscription,
  [NOTIFICATION_CODE.subscription_request_approved]:
    NOTIFICATION_CATEGORY.enquiry,
  [NOTIFICATION_CODE.subscription_request_declined]:
    NOTIFICATION_CATEGORY.enquiry,
  [NOTIFICATION_CODE.subscription_request_cancelled]:
    NOTIFICATION_CATEGORY.enquiry,
  [NOTIFICATION_CODE.subscription_request_review]:
    NOTIFICATION_CATEGORY.enquiry,

  [NOTIFICATION_CODE.data_import_upload_error]:
    NOTIFICATION_CATEGORY.data_import,
  [NOTIFICATION_CODE.data_import_upload_success]:
    NOTIFICATION_CATEGORY.data_import,
  [NOTIFICATION_CODE.data_import_upload_partial]:
    NOTIFICATION_CATEGORY.data_import,

  [NOTIFICATION_CODE.verification_grace_period_expired]:
    NOTIFICATION_CATEGORY.verification_access,
  [NOTIFICATION_CODE.verification_grace_period_nearing_expiration]:
    NOTIFICATION_CATEGORY.verification_access,

  [NOTIFICATION_CODE.watchlist_certifications_update]:
    NOTIFICATION_CATEGORY.watchlist_update,

  [NOTIFICATION_CODE.subscription_request_approved]:
    NOTIFICATION_CATEGORY.enquiry,
  [NOTIFICATION_CODE.subscription_request_cancelled]:
    NOTIFICATION_CATEGORY.enquiry,
  [NOTIFICATION_CODE.subscription_request_declined]:
    NOTIFICATION_CATEGORY.enquiry,
  [NOTIFICATION_CODE.subscription_request_review]:
    NOTIFICATION_CATEGORY.enquiry,

  [NOTIFICATION_CODE.customer_request_approved]: NOTIFICATION_CATEGORY.enquiry,
  [NOTIFICATION_CODE.customer_request_cancelled]: NOTIFICATION_CATEGORY.enquiry,
  [NOTIFICATION_CODE.customer_request_declined]: NOTIFICATION_CATEGORY.enquiry,
  [NOTIFICATION_CODE.customer_request_under_review]:
    NOTIFICATION_CATEGORY.enquiry,
};

export const NOTIFICATION_FREE_TRIAL_CODES = {
  subscription_free_trial_ending: 'subscription_free_trial_ending',
  subscription_free_trial_ended_free: 'subscription_free_trial_ended_free',
  subscription_free_trial_ended_basic: 'subscription_free_trial_ended_basic',
};
