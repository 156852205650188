import styles from './PageHeader.module.scss';

import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Link, useRouteMatch} from 'react-router-dom';
import cx from 'classnames';
import {Navbar} from 'reactstrap';
import {ImageLogo} from 'components/Images';
import {useAuth} from 'contexts/AuthContext';
import PublicNav from './PublicNav';
import PrivateNav from './PrivateNav';
import throttle from 'lodash/throttle';
import useGetRoutes from 'hooks/useGetRoutes';
import {VIEWPORT_WIDTHS} from 'configs';
import {useViewport} from 'contexts/ViewportContext';
import {usePage, withPage} from 'contexts/PageContext';

PageHeader.propTypes = {
  logoSize: PropTypes.oneOf(['md', 'lg']),
};

PageHeader.defaultProps = {
  logoSize: 'md',
  dark: false,
  fluid: true,
  fixed: true,
  sticky: false,
  search: true,
  upgrade: true,
};

function useSticky(isSticky) {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = throttle(() => {
      if (window.scrollY > 200 && !isScrolled) {
        setIsScrolled(true);
      } else if (window.scrollY <= 200 && isScrolled) {
        setIsScrolled(false);
      }
    }, 100);

    if (isSticky)
      window.addEventListener('scroll', handleScroll, {passive: true});

    return () => {
      if (isSticky) window.removeEventListener('scroll', handleScroll);
    };
  }, [isSticky, isScrolled]);

  return {isScrolled};
}

function PageHeader(props) {
  const {logoSize, dark, fluid, sticky, className, fixed, ...rest} = props;
  const {isAuth} = useAuth();
  const {isScrolled} = useSticky(sticky);
  const {sidebar} = usePage();
  const {size} = useViewport();

  // dark: light text on dark background
  // !dark: dark text on light background
  // isStickyMode: dark text on light background

  const isStickyMode = sticky && isScrolled;
  const _logoSize = isStickyMode || isAuth ? 'md' : logoSize;
  let isDarkMode = dark;
  if (isStickyMode) isDarkMode = false;

  // if isStickyMode: light text on light background
  // if !isStickyMode: dark text on dark background

  const isMobile = VIEWPORT_WIDTHS[size] < VIEWPORT_WIDTHS['xl'];
  const privateRoutes = useGetRoutes('private');
  const isPrivate = useRouteMatch(privateRoutes);

  const _className = cx(
    styles['header'],
    {
      [styles['header--fluid']]: fluid,
      [styles['header--light']]: fixed || isStickyMode,
      [styles['header--fixed']]: fixed,
      [styles['header--sticky']]: isStickyMode,
      [styles['header--private']]: isPrivate && !isMobile && !!sidebar,
    },
    className
  );

  return (
    <React.Fragment>
      {/* So the navigation is not broken when scrolling */}
      {sticky && isScrolled && (
        <div className={styles['header--sticky-filler']} />
      )}
      <header style={{zIndex: 1035}} className={_className}>
        <Navbar
          expand="xl"
          dark={isDarkMode}
          className={cx('container-fluid', styles['header-nav'])}
        >
          {(!isPrivate || !sidebar) && (
            <Link to="/">
              <ImageLogo size={_logoSize} />
            </Link>
          )}

          {isAuth ? (
            <PrivateNav dark={isDarkMode} isMobile={isMobile} {...rest} />
          ) : (
            <PublicNav dark={isDarkMode} />
          )}
        </Navbar>
      </header>
    </React.Fragment>
  );
}

export default withPage(PageHeader);
