import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import {BaseButton} from 'components/Buttons';

import {useLimits} from 'contexts/LimitsContext';
import {useMembership} from 'contexts/MembershipContext';

import {SUBSCRIPTION_TYPE} from 'configs/membership';
import {ENABLE_FREE_TRIAL} from 'configs/enable-features';
import {buildUrlParams} from 'utils/string';

function PrivateNavPlan() {
  const {data} = useLimits();
  const {pathname} = useLocation();
  const {isTrial, subscriptionLevel, isTrialDone} = useMembership();
  const {
    total_limit,
    cert_limit_reached,
    available_limit_left,
    company_limit_reached,
  } = data;

  const isNearLimit = (available_limit_left / total_limit) * 100 <= 30; // show only if 30% left

  const enableUpgrade =
    (subscriptionLevel === 0 && !isTrial) ||
    isNearLimit ||
    cert_limit_reached ||
    company_limit_reached;

  if (!enableUpgrade) return null;

  const hasFreeTrial = ENABLE_FREE_TRIAL && !isTrialDone && !subscriptionLevel;

  function generateLink() {
    if (!subscriptionLevel && !isTrialDone) {
      // if free and trial not availed
      return buildUrlParams('/membership/plans/checkout', {
        plan: 'free_trial',
        redirect: pathname,
      });
    }

    if (isTrial) {
      // push upgrade to basic
      return buildUrlParams('/membership/plans/checkout', {
        plan: SUBSCRIPTION_TYPE.basic,
        redirect: pathname,
      });
    }

    // proceed normal upgrade to plans
    return buildUrlParams('/membership/plans', {
      redirect: pathname,
    });
  }

  return (
    <div className="d-none d-sm-block">
      <BaseButton
        outline
        color="danger"
        className="mr-3 rounded-sm"
        tag={Link}
        to={generateLink}
      >
        {hasFreeTrial ? 'Try Basic Plan for Free' : 'Upgrade'}
      </BaseButton>
    </div>
  );
}

export default PrivateNavPlan;
