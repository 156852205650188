import cleanDeep from 'clean-deep';
import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';
import urljoin from 'url-join';

const propTypes = {
  path: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  medium: PropTypes.oneOf(['site']).isRequired,
  content: PropTypes.oneOf(['popup_modal', 'plans_page', 'banner']).isRequired,
  campaign: PropTypes.oneOf([
    'upgrade_early_bird',
    'cert_limit_reached_signup',
    'subscribe_basic',
    'subscribe_standard',
    '7days_free_trial',
  ]).isRequired,
  params: PropTypes.object,
};

const defaultProps = {
  medium: 'site',
  campaign: 'upgrade_early_bird',
};

export function generateUtmLink(props) {
  const {path, source, medium, content, campaign, params} = props;

  const utmTags = {
    utm_source: source,
    utm_medium: medium || defaultProps.medium,
    utm_content: content,
    utm_campaign: campaign || defaultProps.campaign,
    ...params,
  };

  const utmParams = new URLSearchParams(cleanDeep(utmTags)).toString();
  const link = urljoin(path, `?${utmParams}`);

  return link;
}

function UtmLink(props) {
  const {path, params, source, medium, campaign, content, children, ...rest} =
    props;

  const link = generateUtmLink({
    path,
    source,
    medium,
    content,
    campaign,
    params,
  });

  return (
    <Link to={link} {...rest}>
      {children}
    </Link>
  );
}

UtmLink.defaultProps = defaultProps;
UtmLink.propTypes = propTypes;

export default UtmLink;
