export const SUBSCRIPTION_TYPE = {
  free: 'free',
  basic: 'basic',
  standard: 'standard',
  enterprise: 'enterprise',
};

export const SUBSCRIPTION_TYPE_LEVEL = {
  [SUBSCRIPTION_TYPE.free]: 0,
  [SUBSCRIPTION_TYPE.basic]: 5,
  [SUBSCRIPTION_TYPE.standard]: 10,
  [SUBSCRIPTION_TYPE.enterprise]: 1000,
};

export const SUBSCRIPTION_PLANS_LEVEL_TYPE = {
  [SUBSCRIPTION_TYPE_LEVEL[SUBSCRIPTION_TYPE.free]]: SUBSCRIPTION_TYPE.free,
  [SUBSCRIPTION_TYPE_LEVEL[SUBSCRIPTION_TYPE.basic]]: SUBSCRIPTION_TYPE.basic,
  [SUBSCRIPTION_TYPE_LEVEL[SUBSCRIPTION_TYPE.standard]]:
    SUBSCRIPTION_TYPE.standard,
  [SUBSCRIPTION_TYPE_LEVEL[SUBSCRIPTION_TYPE.enterprise]]:
    SUBSCRIPTION_TYPE.enterprise,
};

export const SUBSCRIPTION_TYPE_LABEL = {
  [SUBSCRIPTION_TYPE_LEVEL[SUBSCRIPTION_TYPE.free]]: 'Free',
  [SUBSCRIPTION_TYPE_LEVEL[SUBSCRIPTION_TYPE.basic]]: 'Basic',
  [SUBSCRIPTION_TYPE_LEVEL[SUBSCRIPTION_TYPE.standard]]: 'Standard',
  [SUBSCRIPTION_TYPE_LEVEL[SUBSCRIPTION_TYPE.enterprise]]: 'Enterprise',
};

export const SUBSCRIPTION_TYPE_PLAN_CHECKOUT_LABEL = {
  free_trial: 'Basic Plan (Free Trial)',
  [SUBSCRIPTION_TYPE.basic]: 'Basic Plan',
  [SUBSCRIPTION_TYPE.standard]: 'Standard Plan',
  [SUBSCRIPTION_TYPE.enterprise]: 'Enterprise Plan',
};

export const SUBSCRIPTION_TYPE_COLOR = {
  [SUBSCRIPTION_TYPE_LEVEL[SUBSCRIPTION_TYPE.free]]: 'secondary',
  [SUBSCRIPTION_TYPE_LEVEL[SUBSCRIPTION_TYPE.basic]]: 'warning',
  [SUBSCRIPTION_TYPE_LEVEL[SUBSCRIPTION_TYPE.standard]]: 'primary',
  [SUBSCRIPTION_TYPE_LEVEL[SUBSCRIPTION_TYPE.enterprise]]: 'info',
};

export const SUBSCRIPTION_BILLING_METHOD = {
  stripe: 'stripe',
  manual: 'manual',
};
export const SUBSCRIPTION_BILLING_METHOD_LABEL = {
  [SUBSCRIPTION_BILLING_METHOD['stripe']]: 'Stripe',
  [SUBSCRIPTION_BILLING_METHOD['manual']]: 'Manual',
};
